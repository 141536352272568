import React from 'react';

import './styles.css'

function Menu() {
  return (
    <div className='menu'>
      <div>Articles</div>
      <div>About</div>
    </div>
  );
}

export default Menu;
