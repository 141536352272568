import React from 'react';

import './styles.css'

function Logo() {
  return (
    <div className='logo'>
      Logo
    </div>
  );
}

export default Logo;
