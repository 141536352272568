import React from 'react';

import './styles.css'

function Article() {
  return (
    <div className='article'>
      Article
    </div>
  );
}

export default Article;
